<template>
  <div class="scroll-to-me column q-gutter-md">
    <div class="text-red text-subtitle1" v-if="error">{{template_text.MOVEBOX}}</div>
    <div class="row justify-between no-wrap">
      <Container  @drop="onDrop('btn_opt0',$event)" drag-class="mainrow bg-primary text-white" :class="udb?'col-5':'col'"
                  group-name="1" :get-child-payload="getChildPayload0">
        <Draggable class="row content-center mainrow  bg-grey-3" v-for="item in btn_opt0" :key="item.value">
          <div class="row justify-center">
          <div class="text-h6 " >
            {{item.label}}
          </div></div>
        </Draggable>
      </Container>
      <div class="col-auto self-center">
        <q-icon name="double_arrow" color="primary" size="xl" v-if="udb&&!valid"/>
      </div>
      <Container  @drop="onDrop('btn_opt1',$event)" drag-class="mainrow bg-primary text-white" class="col-5 bg-secondary"
                  group-name="1" :get-child-payload="getChildPayload1" v-if="udb">
        <Draggable class="row content-center mainrow  bg-white" v-for="item in btn_opt1" :key="item.value">
          <div class="row justify-center">
            <div class="text-h6 " >
              {{item.label}}
            </div></div>
        </Draggable>
      </Container>
    </div>
    <div class="col self-end" v-if="(dnext&&valid)">
      <q-btn color="secondary"  push icon-right="navigate_next" :label="template_text.NEXT" @click="onNext"/>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import {applyDrag, shuffle} from "@/helpers/dnd";
export default {
  name: "dragdrop_input",
  components: {Container, Draggable},
  data() {
    return {
      form: this.value || [],
      error: false,
      scrolled:false,
      btn_opt0:[],
      btn_opt1:[],
      forcenext:true
    };
  },
  methods: {
    onDrop(col,dropResult) {
      this[col] = applyDrag(this[col], dropResult);
      this.form = [];
      let out = this.btn_opt0
      if(this.udb) out = this.btn_opt1
      for(let item of out){
        this.form.push(item.value)
      }
      if( this.valid) {
        this.$emit('input', this.form);
        if (!this.forcenext&&this.udb) this.onNext()
      }
    },
    getChildPayload0 (index) {
      return this.btn_opt0[index]
    },
    getChildPayload1 (index) {
      return this.btn_opt1[index]
    },
    async validate() {

      if (this.valid) {
        this.error = false
        return true
      }
      this.error = true;
      return false
    },

     onNext() {
      if ( this.validate()) this.$emit('next');

    },
    init_btn_opt0: function () {
      let data = []
      if(!this.value) { // init value if no input
        if(this.attribut.randomstart) shuffle(this.attribut.values)
        for (let i in this.attribut.values) {
          data.push({value: this.attribut.values[i], label: this.labels[i]})
        }
      } else if (!this.udb) { //reclass values as fct of input (for non dropbox case)
        for (let val of this.value) {
          const index = this.attribut.values.findIndex(value => value===val);
          data.push({value: this.attribut.values[index], label: this.labels[index]})
        }
      }
      return data
    },
    init_btn_opt1: function () {
      let data = []
      if(this.value && this.udb) {
        for (let val of this.value) {
          const index = this.attribut.values.findIndex(value => value===val);
          data.push({value: this.attribut.values[index], label: this.labels[index]})
        }
      }
      return data
    }
  },


  computed: {
    labels: function () {
      return this.attribut.label.find(o => o.language === this.language)
    },
    udb:function(){return this.attribut.dropbox},
    valid:function() {
      if (this.mandatory) {
        return this.form.length === this.attribut.values.length
      } else {
        return this.form.length >0
    }
    }


  },
  props: {
    value: Array,
    attribut: Object,
    // forcenext: Boolean,
    dnext: Boolean,
    template_text: Object,
    mandatory: Boolean,
    language: String,
  },
  mounted() {
    this.btn_opt0 = this.init_btn_opt0();
    this.btn_opt1 = this.init_btn_opt1();


  },

}

</script>

<style lang="sass" scoped>
.mainrow
  border: 0.2rem solid rgba(86,61,124,.2)
  min-height: 50px
.mainrow + .mainrow
  margin-top: 0.5rem
</style>
